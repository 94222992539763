import { SetPagination, SetQueriesObject } from '@/utils/setQueriesObject'

export const getJson = data => ({
    created_at: data.created_at,
    force: data.force,
    id: data.id,
    version: data.version
})
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta)
    data = data.map(user => getJson(user))
    return { data, pagination }
}
export const setData = data => {
    const formData = {
        version: data.version,
        force: data.force,
    }
    return formData
}
export const setQuery = data => SetQueriesObject(data)
