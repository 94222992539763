import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VersionRepository from '@/abstraction/repository/versionRepository'

const repository = new VersionRepository()

export default function useUsersList() {
    // Use toast
    const toast = useToast()

    const versions = ref([])
    const pagination = ref([])

    // Table Handlers
    const tableColumns = [
        { key: 'version', sortable: true },
        { key: 'force', sortable: true },
        { key: 'created', sortable: true },
        { key: 'actions' },
    ]
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)

    const fetchVersions = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const versionsList = await repository.index({ filters, pagination: paginate })
        versions.value = versionsList.data
        pagination.value = versionsList.pagination
        return versions
    }

    const deleteVersions = async (id) => {
        try {
            const response = await repository.delete(id);
            pagination.value.itemsLength--;
            const index = versions.value.findIndex((x) => x.id === id);
            versions.value.splice(index, 1);
            return response;
        } catch (e) {
            return false;
        }
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    return {
        fetchVersions,
        deleteVersions,
        tableColumns,
        perPage,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        versions,
        pagination,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}
