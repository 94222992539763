<template>
    <div>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <!-- Change Version -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row>
                        <b-col cols="4" align-self="center">
                            <validation-provider #default="validationContext" name="Version">
                                <b-form-group
                                    label="Version
              "
                                    label-for="version"
                                >
                                    <b-form-input id="version" v-model="form.version" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="2" align-self="center">
                            <b-form-checkbox id="accountSwitch6" v-model="form.force" name="check-button" switch inline>
                                <span>Force Update</span>
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="6" class="text-right" align-self="center">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit"> Change </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageVideo({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table class="position-relative pb-3" :items="versions" responsive :fields="tableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Video -->
                <template #cell(version)="data">
                    <b-media vertical-align="center">
                        <div>{{ data.item.version }}</div>
                    </b-media>
                </template>
                <!-- Column: Genre -->
                <template #cell(force)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{ data.item.force ? "true" : "false " }}</span>
                    </div>
                </template>
                <!-- Column: Artist -->
                <template #cell(created)="data">
                    <div v-if="data.item.created_at" class="text-nowrap d-flex align-items-center">
                        <span>
                            <i class="WMi-user" />
                        </span>
                        <div class="font-weight-bold d-block text-nowrap">
                            {{ data.item.created_at }}
                        </div>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button @click="deleteBtn(data.item.id)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle">
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageVideo({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BFormGroup, BFormInvalidFeedback, BButton, BTable, BMedia, BForm, BAvatar, BLink, BBadge, BFormCheckbox, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VersionRepository from "@/abstraction/repository/versionRepository";
import useVersionList from "./useVersionList.js";
import userStoreModule from "../user/userStoreModule";
// repository
const versionRepository = new VersionRepository();
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BFormGroup,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BFormCheckbox,
        BForm,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        const blankform = {
            version: "",
            force: false,
        };
        const {
            fetchVersions,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            versions,
            pagination,
            deleteVersions,
            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useVersionList();
        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        const deleteBtn = (id) => {
            console.log(id);
            deleteVersions(id);
        };
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        onMounted(() => {
            fetchVersions();
        });

        const changePageVideo = async (paginate) => {
            await fetchVersions(paginate);
        };
        const form = ref(JSON.parse(JSON.stringify(blankform)));
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankform));
        };
        const onSubmit = async () => {
            try {
                const response = await versionRepository.store(form.value);
                resetform();
                versions.value.unshift(response);
            } catch (e) {
                console.log(e);
                return e;
            }
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);

        return {
            form,
            deleteBtn,
            // Sidebar
            fetchVersions,
            versions,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            changePageVideo,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
