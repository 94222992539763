import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/versionResource'

export default class VersionRepository {
    async index(data) {
        const params = setQuery(data)
        const response = await axios.get(url('indexVersions'), { params })
        if (response.status === 200) {
            return getArray(response.data)
        }
    }
    async store(data) {
        const json = setData(data)
        const response = await axios.post(url('storeVersion'), json)
        if (response.status === 201) {
            return getJson(response.data.data)
        }
    }
    async delete(id) {
        const response = await axios.delete(url("deleteVersion", { version: id }));
        if (response.status === 200) {
            return true;
        }
    }
}
